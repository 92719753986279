import Badge from "./badge";
import Button from "./button";
import Checkbox from "./checkbox";
import Footer from "./footer";
import Modal from "./modal";
import Select from "./select";
import Sidebar from "./sidebar";
import Spinner from "./spinner";
import Tab from "./tab";
import TextInput from "./textInput";
import Textarea from "./textarea";
import Toast from "./toast";
import ToggleSwitch from "./toggleSwitch";
import Tooltip from "./tooltip";

// This folder should only export the styled JSONs for each component in flowbite
const customTheme = {
  ...Badge,
  ...Tab,
  ...Footer,
  ...Button,
  ...TextInput,
  ...Sidebar,
  ...Spinner,
  ...ToggleSwitch,
  ...Textarea,
  ...Checkbox,
  ...Toast,
  ...Tooltip,
  ...Modal,
  ...Select,
};

export default customTheme;
