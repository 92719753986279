import { SlotStatusEnum } from "../types/CalendarTypes";
const INDUSTRIES = ["Tours", "Activities", "Accommodations", "Rentals", "Other"];
const DATE_TIME_FORMAT = [
  {
    label: "Year-month-day",
    options: [
      { label: "22/12/14", value: "y/m/d" },
      { label: "2022-12-14", value: "Y-m-d" },
      { label: "2022, Dec 14, Wed", value: "Y, M d, D" }
    ]
  },
  {
    label: "Day-month-year",
    options: [
      { label: "14/12/22", value: "d/m/y" },
      { label: "14-12-2022", value: "d-m-Y" },
      { label: "Wed, 14 Dec, 2022", value: "D, d M, Y" }
    ]
  },
  {
    label: "Month-day-year",
    options: [
      { label: "12/14/22", value: "m/d/y" },
      { label: "12-14-2022", value: "m-d-y" },
      { label: "Wed, Dec 14, 2022", value: "D, M d, Y" },
      { label: "Dec 14, 2022", value: "M j, Y" }
    ]
  }
];
const DATE_TIME_FORMAT_DEFAULT_VALUE = {
  label: "2022-12-14",
  value: "Y-m-d"
};
const WEEKDAYS = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" }
];
const WEEKDAYS_DEFAULT_VALUE = WEEKDAYS[0];
const TIME_FORMAT_OPTIONS = [
  { label: "12h", value: "12h" },
  { label: "24h", value: "24h" }
];
const QUANTITY_INDICATOR_OPTIONS = [
  { label: "Dropdown", value: "dropdown", showLabel: "Dropdown" },
  {
    label: "Increment/Decrement Button",
    value: "increment_decrement_button",
    showLabel: "Button"
  }
];
const QUANTITY_INDICATOR_OPTION = QUANTITY_INDICATOR_OPTIONS[1];
const TIME_FORMAT_DEFAULT_OPTION = TIME_FORMAT_OPTIONS[0];
const ORDER_NUMBER_FORMAT_OPTIONS = [
  {
    label: "Sequential",
    value: "sequential"
  },
  {
    label: "Numeric",
    value: "numeric"
  },
  {
    label: "Alphanumeric",
    value: "alphanumeric"
  }
];
const ORDER_NUMBER_FORMAT_DEFAULT_OPTION = ORDER_NUMBER_FORMAT_OPTIONS[0];
const SHOW_ORDER_NUMBER_PREFIX_ON = ["Alphanumeric", "Sequential"];
const PHP_TO_MOMENT_REPLACEMENTS = {
  d: "DD",
  D: "ddd",
  j: "D",
  l: "dddd",
  N: "E",
  S: "o",
  w: "e",
  z: "DDD",
  W: "W",
  F: "MMMM",
  m: "MM",
  M: "MMM",
  n: "M",
  t: "",
  // no equivalent
  L: "",
  // no equivalent
  o: "YYYY",
  Y: "YYYY",
  y: "YY",
  a: "a",
  A: "A",
  B: "",
  // no equivalent
  g: "h",
  G: "H",
  h: "hh",
  H: "HH",
  i: "mm",
  s: "ss",
  u: "SSS",
  e: "zz",
  // deprecated since Moment.js 1.6.0
  I: "",
  // no equivalent
  O: "",
  // no equivalent
  P: "",
  // no equivalent
  T: "",
  // no equivalent
  Z: "",
  // no equivalent
  c: "",
  // no equivalent
  r: "",
  // no equivalent
  U: "X"
};
const AVAILABILITY_DETAILS_ITEMS = [
  {
    label: "Maximum number of Redemption",
    name: "is_max_redemption_time",
    input: "max_redemption_time",
    placeholder: "Quantity"
  },
  {
    label: "Minimum Order Price",
    name: "is_min_order_price",
    input: "min_order_price",
    placeholder: "Enter Minimum Order Price"
  },
  {
    label: "Maximum Order Price",
    name: "is_max_order_price",
    input: "max_order_price",
    placeholder: "Enter Maximum Order Price"
  }
];
const APPLIES_OPTIONS = [
  {
    label: "Total Order",
    id: "total_order",
    value: "total_order"
  },
  {
    label: "Product Quantity",
    value: "product_quantity",
    id: "product_quantity"
  }
];
const PROMO_CODE_TYPES = [
  {
    label: "Percentage",
    id: "code_type_percentage",
    value: "percent"
  },
  {
    label: "Fixed",
    id: "code_type_fix",
    value: "fixed"
  }
];
const SAMPLE_PRODUCT_ITEMS = [
  {
    label: "AC Room",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a1"
  },
  {
    label: "Party Hall",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a2"
  },
  {
    label: "Boating",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a3"
  },
  {
    label: "Club",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a4"
  }
];
var PROMOCODE_STATUS = /* @__PURE__ */ ((PROMOCODE_STATUS2) => {
  PROMOCODE_STATUS2["SCHEDULED"] = "scheduled";
  PROMOCODE_STATUS2["COMPLETED"] = "completed";
  PROMOCODE_STATUS2["EXPIRED"] = "expired";
  PROMOCODE_STATUS2["ACTIVE"] = "active";
  PROMOCODE_STATUS2["INACTIVE"] = "inactive";
  return PROMOCODE_STATUS2;
})(PROMOCODE_STATUS || {});
const PROMOCODE_EDITABLE_STATUS_OPTIONS = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "In-active" }
];
const industries = ["Tours", "Activities", "Accommodations", "Rentals", "Other"];
const DateTimeFormat = [
  {
    label: "Year-month-day",
    options: [
      { label: "22/12/14", value: "y/m/d" },
      { label: "2022-12-14", value: "Y-m-d" },
      { label: "2022, Dec 14, Wed", value: "Y, M d, D" }
    ]
  },
  {
    label: "Day-month-year",
    options: [
      { label: "14/12/22", value: "d/m/y" },
      { label: "14-12-2022", value: "d-m-Y" },
      { label: "Wed, 14 Dec, 2022", value: "D, d M, Y" }
    ]
  },
  {
    label: "Month-day-year",
    options: [
      { label: "12/14/22", value: "m/d/y" },
      { label: "12-14-2022", value: "m-d-y" },
      { label: "Wed, Dec 14, 2022", value: "D, M d, Y" }
    ]
  }
];
const DateTimeFormatDefaultValue = {
  label: "2022-12-14",
  value: "Y-m-d"
};
const AvailabilityDetailsItems = [
  {
    label: "Maximum number of Redemption",
    name: "is_max_redemption_time",
    input: "max_redemption_time",
    placeholder: "Quantity"
  },
  {
    label: "Minimum Order Price",
    name: "is_min_order_price",
    input: "min_order_price",
    placeholder: "Enter Minimum Order Price"
  },
  {
    label: "Maximum Order Price",
    name: "is_max_order_price",
    input: "max_order_price",
    placeholder: "Enter Maximum Order Price"
  }
];
const appliesOptions = [
  {
    label: "Total Order",
    id: "total_order",
    value: "total_order"
  },
  {
    label: "Product Quantity",
    value: "product_quantity",
    id: "product_quantity"
  }
];
const promoCodeTypes = [
  {
    label: "Percentage",
    id: "code_type_percentage",
    value: "percent"
  },
  {
    label: "Fixed",
    id: "code_type_fix",
    value: "fixed"
  }
];
const promoCodeStatus = {
  SCHEDULED: "scheduled",
  COMPLETED: "completed",
  EXPIRED: "expired",
  ACTIVE: "active",
  INACTIVE: "inactive"
};
var ProductInternalTypeEnum = /* @__PURE__ */ ((ProductInternalTypeEnum2) => {
  ProductInternalTypeEnum2["ACTIVE"] = "active";
  ProductInternalTypeEnum2["INACTIVE"] = "inactive";
  return ProductInternalTypeEnum2;
})(ProductInternalTypeEnum || {});
const PromoCodeEditableStatusOptions = [
  { value: "active" /* ACTIVE */, label: "Active" },
  { value: "inactive" /* INACTIVE */, label: "In-active" }
];
const TAX_AND_FEES_CATEGORY = [
  {
    label: "Tax",
    value: 1
  },
  {
    label: "Fee",
    value: 2
  }
];
const TAX_AND_FEES_TYPES = [
  {
    label: "Percentage",
    value: 1
  },
  {
    label: "Fixed per order item",
    value: 2
  },
  {
    label: "Fixed per quantity",
    value: 3
  },
  {
    label: "Fixed per duration",
    value: 4
  }
];
const ASK_PER_ORDER = "ask_per_order";
const ASK_PER_PARTICIPANT = "ask_per_participant";
const ASK_PER_BOOKING = "ask_per_booking";
const ASK_PER_ITEM = "ask_per_item";
const PRODUCT_STATUS = {
  ENABLED: "Active",
  DISABLED: "Disabled"
};
const VOUCHER_TYPE = [
  {
    label: "Manual",
    value: "manual",
    id: "manual"
  },
  {
    label: "Auto-Generate",
    value: "automatic",
    id: "automatic"
  }
];
const APPLICABLE_DATE_TYPE = [
  {
    label: "Booking",
    value: "booking"
  },
  {
    label: "Order",
    value: "order"
  }
];
const VoucherAvailabilityDetailsItems = [
  {
    label: "minimum Amount",
    name: "is_min_order_price",
    input: "min_order_price",
    placeholder: "Enter Minimum Amount",
    type: "number"
  }
];
const VoucherDateFormat = "Y-m-d H:i:s";
var ProductInternalTypeEnum = /* @__PURE__ */ ((ProductInternalTypeEnum2) => {
  ProductInternalTypeEnum2["BOOKING"] = "bookable";
  ProductInternalTypeEnum2["MERCHANDISE"] = "merchandise";
  ProductInternalTypeEnum2["EXTRA"] = "extra";
  ProductInternalTypeEnum2["GIFT_CARD"] = "gift_card";
  ProductInternalTypeEnum2["RENTALS"] = "rentals";
  return ProductInternalTypeEnum2;
})(ProductInternalTypeEnum || {});
const ProductClosureStatusEnum = {
  Pending: "Pending",
  Active: "Active",
  Disabled: "Disabled",
  Archived: "Archived"
};
const CustomerStatusEnum = {
  ACTIVE: "ACTIVE",
  Inactive: "Inactive"
};
const SLOT_STATUS_SELECT_OPTIONS = [
  {
    label: "Open",
    value: SlotStatusEnum.OPEN
  },
  {
    label: "Closed",
    value: SlotStatusEnum.CLOSED
  }
];
const ACTION = {
  add: "Add",
  edit: "Edit",
  create: "Create",
  update: "Update",
  delete: "Delete",
  archive: "Archived",
  view: "View"
};
const domainPlaceholder = ".smartrezbooking.com";
const widgetsDomainName = "SmartRez Booking";
const TEMPLATES_STATUS = {
  ACTIVE: "active",
  DRAFT: "draft"
};
var ProductInternalTypesEnum = /* @__PURE__ */ ((ProductInternalTypesEnum2) => {
  ProductInternalTypesEnum2["ACTIVE"] = "Active";
  ProductInternalTypesEnum2["INACTIVE"] = "Disabled";
  return ProductInternalTypesEnum2;
})(ProductInternalTypesEnum || {});
const ProductEditableStatusOptions = [
  { value: "Active" /* ACTIVE */, label: "Active" },
  { value: "Disabled" /* INACTIVE */, label: "In-active" }
];
const TemplateStatusOptions = [
  {
    value: TEMPLATES_STATUS.ACTIVE,
    label: "Active"
  },
  {
    value: TEMPLATES_STATUS.DRAFT,
    label: "Draft"
  }
];
var SignedWaiverTypesEnum = /* @__PURE__ */ ((SignedWaiverTypesEnum2) => {
  SignedWaiverTypesEnum2["Approved"] = "approved";
  SignedWaiverTypesEnum2["Pending"] = "pending";
  SignedWaiverTypesEnum2["Declined"] = "declined";
  return SignedWaiverTypesEnum2;
})(SignedWaiverTypesEnum || {});
var SignedWaiverLevelTypesEnum = /* @__PURE__ */ ((SignedWaiverLevelTypesEnum2) => {
  SignedWaiverLevelTypesEnum2["Booking"] = "booking";
  SignedWaiverLevelTypesEnum2["Participant"] = "participant";
  return SignedWaiverLevelTypesEnum2;
})(SignedWaiverLevelTypesEnum || {});
const SignedWaiverOptions = [
  {
    value: "approved" /* Approved */,
    label: "Approved"
  },
  {
    value: "pending" /* Pending */,
    label: "Pending"
  },
  {
    value: "declined" /* Declined */,
    label: "Declined"
  }
];
const TicketTypeOptions = [
  // To DO:: add these option in to the future
  // {
  //   label:'Fixed',
  //   value:'fixed'
  // },
  // {
  //   label:'Per Person',
  //   value:'per_person'
  // },
  // {
  //   label:'Per Unit',
  //   value:'per_unit'
  // },
  {
    label: "Per Minute",
    value: "per_minute",
    isSingleDayOption: true
  },
  {
    label: "Per Hour",
    value: "per_hour",
    isSingleDayOption: true
  },
  {
    label: "Per Day",
    value: "per_day",
    isSingleDayOption: false
  },
  {
    label: "Per Week",
    value: "per_week",
    isSingleDayOption: false
  },
  {
    label: "Per Month",
    value: "per_month",
    isSingleDayOption: false
  },
  {
    label: "Per Year",
    value: "per_year",
    isSingleDayOption: false
  }
];
var SignedWaiverStatusEnum = /* @__PURE__ */ ((SignedWaiverStatusEnum2) => {
  SignedWaiverStatusEnum2["Signed"] = "signed";
  SignedWaiverStatusEnum2["Unsigned"] = "unsigned";
  return SignedWaiverStatusEnum2;
})(SignedWaiverStatusEnum || {});
const getDayName = (dayNumber) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const index = Math.min(Math.max(0, dayNumber), 6);
  return daysOfWeek[index];
};
export {
  ACTION,
  APPLICABLE_DATE_TYPE,
  APPLIES_OPTIONS,
  ASK_PER_BOOKING,
  ASK_PER_ITEM,
  ASK_PER_ORDER,
  ASK_PER_PARTICIPANT,
  AVAILABILITY_DETAILS_ITEMS,
  AvailabilityDetailsItems,
  CustomerStatusEnum,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_DEFAULT_VALUE,
  DateTimeFormat,
  DateTimeFormatDefaultValue,
  INDUSTRIES,
  ORDER_NUMBER_FORMAT_DEFAULT_OPTION,
  ORDER_NUMBER_FORMAT_OPTIONS,
  PHP_TO_MOMENT_REPLACEMENTS,
  PRODUCT_STATUS,
  PROMOCODE_EDITABLE_STATUS_OPTIONS,
  PROMOCODE_STATUS,
  PROMO_CODE_TYPES,
  ProductClosureStatusEnum,
  ProductEditableStatusOptions,
  ProductInternalTypeEnum,
  ProductInternalTypesEnum,
  PromoCodeEditableStatusOptions,
  QUANTITY_INDICATOR_OPTION,
  QUANTITY_INDICATOR_OPTIONS,
  SAMPLE_PRODUCT_ITEMS,
  SHOW_ORDER_NUMBER_PREFIX_ON,
  SLOT_STATUS_SELECT_OPTIONS,
  SignedWaiverLevelTypesEnum,
  SignedWaiverOptions,
  SignedWaiverStatusEnum,
  SignedWaiverTypesEnum,
  TAX_AND_FEES_CATEGORY,
  TAX_AND_FEES_TYPES,
  TEMPLATES_STATUS,
  TIME_FORMAT_DEFAULT_OPTION,
  TIME_FORMAT_OPTIONS,
  TemplateStatusOptions,
  TicketTypeOptions,
  VOUCHER_TYPE,
  VoucherAvailabilityDetailsItems,
  VoucherDateFormat,
  WEEKDAYS,
  WEEKDAYS_DEFAULT_VALUE,
  appliesOptions,
  domainPlaceholder,
  getDayName,
  industries,
  promoCodeStatus,
  promoCodeTypes,
  widgetsDomainName
};
