const routes = {
  root: "/",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  impersonate: "/impersonate",
  resetPassword: "/password/reset",
  verifyEmail: "/email/verify",
  registerBusiness: "/register-business",
  registerUser: "/register-user",
  selectDomain: "/select-domain",
  dashboard: "/dashboard",
  orders: {
    root: "/orders",
    summary: "/orders/:id",
    viewOrder: "/orders/view/:id"
  },
  payOrder: {
    root: "/pay-order/[cardId]"
  },
  bookings: {
    root: "/bookings",
    viewBooking: "/bookings/view/:id"
  },
  invoiceRoot: "/invoice",
  invoice: "/invoice/:id",
  reports: {
    root: "/reports",
    sales: {
      root: "/reports/sales",
      order: "/reports/sales/order",
      booking: "/reports/sales/booking",
      product: "/reports/sales/product",
      category: "/reports/sales/category",
      customer: "/reports/sales/customer"
    },
    transactions: {
      simplified: "/reports/transactions/simplified",
      detailed: "/reports/transactions/detailed"
    },
    activity: "/reports/activity",
    actionHistory: "/reports/action-history",
    tips: {
      root: "/reports/tips",
      crew: "/reports/tips/crew-tips"
    },
    bookings: {
      root: "/reports/bookings"
    },
    payouts: {
      root: "/reports/payouts"
    },
    voucher: "/reports/voucher",
    commissionPayout: "/reports/commission-payout"
  },
  staffManagement: {
    root: "/staff-management",
    pendingInvites: "/staff-management/pending-invites",
    roleManagement: "/staff-management/role-management",
    crewTypes: "/staff-management/crew-types"
  },
  communications: {
    root: "/communications",
    messageLogs: "/communications/message-logs",
    smsTemplates: "/communications/sms-templates"
  },
  settings: {
    root: "/settings",
    account: {
      root: "/settings/account",
      notifications: "/settings/account/notifications"
    },
    integrations: {
      google: "/settings/integrations/google"
    },
    businessProfile: {
      root: "/settings/business-profile",
      regionalSettings: "/settings/business-profile/business-settings",
      subscription: "/settings/business-profile/subscription",
      businessHours: "/settings/business-profile/business-hours",
      integrations: "/settings/business-profile/integration",
      peroder: "/settings/business-profile/per-order"
    },
    paymentSettings: {
      root: "/settings/payment-settings",
      depositAndFees: "/settings/payment-settings/deposit-fees",
      taxesAndFees: "/settings/payment-settings/taxes-fees",
      tipSettings: "/settings/payment-settings/tip-settings"
    },
    manageCategorySetting: "/communications/:id"
  },
  promoCode: {
    root: "/inventory/promo-code",
    add: "/inventory/promo-code/new",
    edit: "/inventory/promo-code/:id"
  },
  voucher: {
    root: "/inventory/voucher",
    add: "/inventory/voucher/new",
    edit: "/inventory/voucher/:id"
  },
  assets: {
    root: "/inventory/assets",
    closure: "/inventory/assets/:id/closures",
    categories: "/inventory/assets/categories"
  },
  pricingSeason: {
    root: "/inventory/pricing-season",
    past: "/inventory/pricing-season/past"
  },
  merchandise: {
    root: "/inventory/merchandise",
    type: "/inventory/merchandise/:id/type",
    info: "/inventory/merchandise/:id/info",
    ticket: "/inventory/merchandise/:id/ticket",
    price: "/inventory/merchandise/:id/price",
    summary: "/inventory/merchandise/:id/summary"
  },
  product: {
    root: "/inventory/product",
    add: "/inventory/product/add",
    edit: "/inventory/product/:id",
    type: "/inventory/product/:id/type",
    info: "/inventory/product/:id/info",
    location: "/inventory/product/:id/location",
    inventory: "/inventory/product/:id/inventory",
    scheduling: "/inventory/product/:id/scheduling",
    ticket: "/inventory/product/:id/ticket",
    price: "/inventory/product/:id/price",
    booking: "/inventory/product/:id/booking",
    extra: "/inventory/product/:id/extra",
    summary: "/inventory/product/:id/summary",
    "value-and-expiry": "/inventory/product/:id/value-and-expiry",
    "gift-extra": "/inventory/product/:id/gift-extra",
    "gift-summary": "/inventory/product/:id/gift-summary",
    productCategory: "/inventory/product/product-category",
    productLocations: "/inventory/product/product-locations",
    productClosures: "/inventory/product/product-closures"
  },
  locations: {
    add: "/location/add"
  },
  widgets: {
    root: "/widgets",
    integratedWidget: "/integrated_widget",
    affiliate_widget: "/widgets/affiliate"
  },
  affiliate: {
    root: "/affiliates",
    commissionCategory: "/affiliates/commission-category",
    pendingInvites: "/affiliates/pending-invites",
    commissions: "/affiliates/commissions",
    invoices: "/affiliates/invoices"
  },
  customers: "/customers",
  calendar: {
    root: "/calendar/products",
    booking: "/calendar/booking",
    crewSchedule: "/calendar/crew-schedule"
  },
  manifest: "/manifest",
  "500": "/500",
  TnC: "/terms-and-conditions",
  // TODO: BUILD THIS PAGE IN FEATURE
  privacy: "/privacy-policy",
  // TODO: BUILD THIS PAGE IN FEATURE
  waivers: {
    root: "/waivers/templates",
    signedWaiver: "/waivers/signed-waiver",
    kiosk: "/waivers/kiosk"
  },
  webview: "webview"
};
var routes_default = routes;
export {
  routes_default as default
};
